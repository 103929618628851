import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/terms-of-use.scss"

const TermsOfUsePage = () => (
  <Layout className="terms-of-use">
    <SEO
      title="Terms of Use"
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow",
        },
      ]}
    />
    <article className="max-width">
      <h1>FIREEYE DEVELOPER HUB TERMS OF USE</h1>
      <p>
        <span>
          The FireEye Developer Hub (the &quot;Developer Hub&quot;), including
          the FireEye Developer Docs and FireEye Developer Community sites
          (collectively, the “FireEye Developer Hub” or the “Developer Hub”) is
          owned and operated by FireEye, Inc. and its affiliated entities
          (&quot;FireEye&quot; or &quot;we&quot; or &quot;us&quot;). The
          Developer Hub is an online community space where we invite users and
          developers to share feedback, ideas, projects, documents, graphics,
          text, code, tools, or other materials (collectively, “Developer Hub
          Content”) for discussion and use by other Developer Hub participants
          (each, a &quot;Developer Hub Participant&quot;).
        </span>
      </p>

      <p>
        <span>
          To access portions of the Developer Hub or certain resources, you may
          be asked to provide certain registration details or other information.
          It is a condition of your use of the Developer Hub, and you hereby
          represent and warrant, that you are of the legal age of majority in
          the jurisdiction in which you reside and that all information you
          provide is correct, current and complete. If you choose, or you are
          provided with, a user name, password or other information as part of
          our security procedures, you must treat such information as
          confidential and you must not disclose it to any third party. You
          agree that you are solely responsible for all activities associated
          with or undertaken through your account. You agree to immediately
          notify FireEye of any unauthorized use of your user name or password
          or any other breach of security related to the Developer Hub.
        </span>
      </p>

      <ol start="1" type="1">
        <li>
          <em>
            <b>
              <u>Binding Agreement</u>
            </b>
          </em>
          <span>
            . By visiting the Developer Hub website, you agree that your
            visiting and use of the Developer Hub website is governed by these
            FireEye Developer Hub Terms of Use (&quot;Terms of Use&quot;), which
            you acknowledge represent a binding agreement between you and
            FireEye. If you are agreeing to these Terms of Use on behalf of a
            company or other organization, you represent that you have the
            authority to bind that company or organization to these Terms of
            Use, and the terms &quot;you&quot; and &quot;your&quot; will refer
            to that company or organization. If you do not have that authority,
            or if you do not agree with these Terms of Use, you may not use the
            Developer Hub or any of the Developer Hub Content. FireEye reserves
            the right to make changes to Developer Hub and to these Terms of Use
            at any time. Your continued use of Developer Hub and any Developer
            Hub Content will constitute your acceptance of any new or amended
            Terms of Use for the Developer Hub.
          </span>
        </li>
        <li>
          <em>
            <b>
              <u>Availability of the Developer Hub</u>
            </b>
          </em>
          <span>
            . FireEye reserves the right to modify, suspend, or discontinue the
            Developer Hub at any time. We will not be liable if, for any reason,
            all or any part of the Developer Hub is unavailable at any time or
            for any period. To the extent you contribute Developer Hub Content,
            and wish for that Developer Hub Content to continue to be available
            to you after you post it, you are encouraged to archive that
            Developer Hub Content locally or in another medium, as FireEye does
            not guarantee that any Developer Hub Content will continue to be
            available to you or any other Developer Hub Participant once posted.
            From time to time, we may restrict access to all or part of the
            Developer Hub to registered users or another subset of users. You
            are solely responsible for making all arrangements necessary to
            access the Developer Hub.
          </span>
        </li>
        <li>
          <em>
            <b>
              <u>Contributing to the Developer Hub</u>
            </b>
          </em>
          .
          <ol start="1" type="i">
            <li>
              <em>
                <b>
                  <u>Overview</u>
                </b>
              </em>
              <span>
                . Developer Hub Content made available on the Developer Hub may
                be provided and licensed to you by FireEye, or by third parties
                including other Developer Hub Participants (when publishing,
                posting, or otherwise contributing Developer Hub Content, a
                “Publisher”). In the Developer Hub, you can access, browse,
                view, use, and contribute Developer Hub Content. Developer Hub
                Content is licensed for your personal and internal research and
                informational use only.
              </span>
            </li>
            <li>
              <b>
                <i>
                  <u>Publishing Developer Hub Content</u>
                </i>
              </b>
              <i>.</i>
              <span>
                You may be allowed to contribute, post, or publish Developer Hub
                Content as a Publisher. When you are acting as a Publisher, you
                represent and warrant that you have all rights and permissions
                necessary to post the Developer Hub Content on the Developer
                Hub, and that your posting and publication of the Developer Hub
                Content does not violate any right of any person, including any
                privacy or publicity rights, copyrights, patent rights, trade
                secret rights or other obligations of confidentiality, or
                similar rights anywhere in the world. You are solely responsible
                for the Developer Hub Content you publish to the Developer Hub.
              </span>
            </li>
            <li>
              <b>
                <i>
                  <u>License.</u>
                </i>
              </b>
              <span>
                You remain the owner of all Developer Hub Content that you
                publish, post, or otherwise contribute to the Developer Hub, but
                you grant to FireEye and other Developer Hub Participants a
                non-exclusive, worldwide, perpetual, irrevocable, transferable,
                sublicensable, fully-paid license to use, reproduce, prepare
                derivatives of, publicly display and/or perform, publicly
                distribute, modify, and adapt the Developer Hub Content in all
                formats and media. You further grant to FireEye a non-exclusive,
                worldwide, perpetual, irrevocable, transferable, sublicensable,
                fully-paid license to use Developer Hub Content, including any
                identifying information such as your user name and any visual
                likenesses you make available to Developer Hub Participants, in
                connection with promoting, advertising, and marketing the
                Developer Hub or any FireEye product, service, or subscription.
                You hereby waive any claims and assertions of moral rights,
                including any rights of attribution, that may be associated with
                the Developer Hub Content that you publish, post, or otherwise
                contribute to the Developer Hub. Any feedback you provide to
                FireEye directly or indirectly related to the Developer Hub or
                any FireEye product, service, or subscription, is provided
                gratuitously, and FireEye may use any such feedback freely
                without any obligations of compensation or otherwise to you.
              </span>
            </li>
          </ol>
        </li>
        <li>
          <em>
            <b>
              <u>Developer Hub Content</u>
            </b>
          </em>
          .
          <ol start="1" type="i">
            <li>
              <em>
                <b>
                  <u>Developer Hub Content Provided &quot;As Is&quot;</u>
                </b>
              </em>
              <span>
                . Developer Hub Content may or may not have been reviewed,
                tested or validated by FireEye, and FireEye assumes no
                responsibility for any Developer Hub Content posted by Developer
                Hub Participants. The posting and availability of any Developer
                Hub Content shall not be construed as making any representation,
                warranty, guarantee or promise apart from those set forth in
                these Terms of Use, nor that your use of any Developer Hub
                Content is permitted by third parties or any applicable laws and
                regulations. You are solely responsible for independently
                testing and validating any Developer Hub Content that
                constitutes code, an API, a tool, or an indicator of compromise
                before deploying it in a production environment, and evaluating
                whether using any Developer Hub Content is permitted in your
                jurisdiction.
              </span>
            </li>
            <li>
              <b>
                <i>
                  <u>Prohibited Content</u>
                </i>
              </b>
              <i>
                <span>
                  .<u> </u>
                </span>
              </i>
              <span>
                FireEye may, but is under no obligation to, monitor or moderate
                Developer Hub Content, and we are not responsible for the
                accuracy, completeness, appropriateness or legality of anything
                posted, depicted or otherwise provided by Developer Hub
                Participants. FireEye disclaims any and all liability relating
                thereto. Developer Hub Content that you publish, post, or
                otherwise contribute may not contain, and FireEye reserves the
                right to remove Developer Hub Content that, in FireEye’s
                exclusive opinion:
              </span>
              <ol start="1" type="a">
                <li>
                  <span>
                    is harmful, threatening, abusive, insulting, harassing,
                    defamatory, libelous, profane, sexually explicit, obscene,
                    or otherwise offensive or objectionable;
                  </span>
                </li>
                <li>is false, fraudulent or misleading;</li>
                <li>
                  <span>
                    violates applicable laws, rules, regulations or guidelines
                    or rights of third parties; including without limitation any
                    privacy, intellectual property or other rights of a third
                    party, or suggests or encourages unlawful activity;
                  </span>
                </li>
                <li>
                  <span>
                    constitutes advertising or any other form of commercial
                    solicitation; or impersonates any other person or entity,
                    including any of FireEye&apos;s employees.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <em>
            <b>
              <u>Privacy and Your Data</u>
            </b>
          </em>
          .
          <ol start="1" type="a">
            <li>
              <em>
                <b>
                  <u>Consent to Use Data and Personal Information</u>
                </b>
              </em>
              <span>
                . You expressly acknowledge, agree and consent that FireEye may
                collect and use personal information, technical data and related
                information, (including without limitation, your name, your IP
                address, company name (if any), addresses (including e- mail
                address) and phone number) as well as technical information
                relating to your device, system, and browser, that is gathered
                periodically to facilitate the provision of the Developer Hub
                and Developer Hub Content. FireEye may use this information, as
                long as it is in a form that does not personally identify you,
                to improve its products and services, develop new offerings,
                and/or to provide services or technology to you (including the
                Developer Hub and Developer Hub Content).
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Privacy Policy</u>
                </b>
              </em>
              <span>
                . Any information FireEye receives from you is subject to the
                FireEye Privacy Policy located at&nbsp;
              </span>
              <span>
                <a
                  href="https://www.fireeye.com/company/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.fireeye.com/company/privacy.html
                </a>
              </span>
              .
            </li>
          </ol>
        </li>
        <li>
          <em>
            <b>
              <u>General</u>
            </b>
          </em>
          .
          <ul>
            <li>
              <em>
                <b>
                  <u>Prohibited Uses</u>
                </b>
              </em>
              <span>
                . You agree not to access or use the Developer Hub in any
                manner:
              </span>
              <ul>
                <li>
                  <span>
                    That violates any applicable federal, state, local or
                    international law or regulation, or advocates, promotes or
                    assists in any unlawful act, constitutes an illegal threat
                    or violates export control laws.
                  </span>
                </li>
                <li>
                  <span>
                    That violates the rights of any person or entity that may
                    give rise to civil or criminal liability under applicable
                    laws or regulations applicable to you, another user, and/or
                    FireEye, including violation of privacy or publicity rights,
                    infringement of any copyright, patent, trademark, trade
                    secret or other intellectual property right, or conflicts
                    with these Terms of Use or the FireEye Privacy Policy.
                  </span>
                </li>
                <li>
                  <span>
                    To transmit or introduce offensive materials, including
                    those involving profanity, violence, sexual conduct,
                    pornography or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age.
                  </span>
                </li>
                <li>
                  <span>
                    To monitor or attempt to gain unauthorized access to: (a)
                    any data, information or communications on any network or
                    system not owned by you, without authorization; or (b) any
                    system or network user accounts or passwords of other users,
                    without authorization.
                  </span>
                </li>
                <li>
                  <span>
                    That is false, deceptive, misleading or fraudulent,
                    including but not limited to: (a) any attempt to impersonate
                    any person or entity; (b) to misrepresent your identity or
                    affiliation with any person or organization; and (c) any
                    attempt to give the impression that you are posting
                    materials from any person or entity other than yourself if
                    that is not the case, including but not limited to altering
                    your IP source address.
                  </span>
                </li>
                <li>
                  <span>
                    To monitor or copy materials from the Developer Hub,
                    including Developer Hub Content, for any unauthorized
                    purpose or access the Developer Hub via any automatic
                    device, process or means of access such as a robot, scraper,
                    or spider.
                  </span>
                </li>
                <li>
                  <span>
                    That is in violation of export or import laws, rules or
                    regulations.
                  </span>
                </li>
                <li>
                  <span>
                    That may have a detrimental effect on the Developer Hub’s
                    functionality, performance, availability, integrity, or
                    security, including but not limited to: (a) gaining
                    unauthorized access to, or attempting to compromise the
                    security of, any network, system, computing facility,
                    equipment, data or information; (b) attempting to intercept,
                    redirect or otherwise interfere with communications intended
                    for others; (c) disabling, damaging, overburdening or
                    impairing the Developer Hub or any server, computer or
                    database connected to or accessed by the Developer Hub; (d)
                    modifying, blocking or otherwise interfering with the
                    display of the Developer Hub; (e) interfering with another
                    user’s ability to access, use and enjoy the Developer Hub;
                    (f) accessing another user’s registration information or
                    user account without that user’s express written permission;
                    (g) transmitting or introducing any malicious or
                    technologically harmful element to the Developer Hub such as
                    a spyware program, virus, Trojan horse, worm or logic bomb;
                    (h) performing, without FireEye’s express prior written
                    authorization, scalability testing, load testing, probing,
                    scanning, penetration or vulnerability testing of the
                    Developer Hub; and (i) engaging in any activities that
                    results in any server serving the Developer Hub being the
                    target of a denial of service attack.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <ol start="1" type="a">
            <li>
              <em>
                <b>
                  <u>DISCLAIMER</u>
                </b>
              </em>
              <span>
                . THE MATERIALS MADE AVAILABLE ON THE DEVELOPER HUB, INCLUDING
                WITHOUT LIMITATION THE DEVELOPER HUB CONTENT, ARE PROVIDED “AS
                IS” AND WITHOUT WARRANTY. YOU ACKNOWLEDGE AND AGREE THAT YOU
                WILL EXERCISE YOUR OWN INDEPENDENT ANALYSIS AND JUDGMENT IN YOUR
                USE OF THESE MATERIALS AND THE DEVELOPER HUB CONTENT. FIREEYE
                ASSUMES NO LIABILITY FOR YOUR USE OF THESE MATERIALS, THE
                DEVELOPER HUB CONTENT OR ANY ASSISTANCE PROVIDED BY FIREEYE.
                FIREEYE AND ITS SUPPLIERS ALONG WITH ANY OTHER PUBLISHER
                EXPRESSLY DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING
                BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES AND CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY,
                TITLE AND NON-INFRINGEMENT OF ANY THIRD PARTY INTELLECTUAL
                PROPERTY RIGHT. FIREEYE MAKES NO WARRANTY AS TO THE RELIABILITY,
                USEFULNESS, COMPLETENESS, TIMELINESS, QUALITY, SECURITY,
                SUITABILITY, AVAILABILITY OR ACCURACY OF ANY DEVELOPER HUB
                CONTENT OR THE DEVELOPER HUB ITSELF OR THAT THE DEVELOPER HUB OR
                DEVELOPER HUB CONTENT WILL BE ERROR FREE OR OPERATE WITHOUT
                INTERRUPTION. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH
                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Indemnification</u>
                </b>
              </em>
              <span>
                . To the maximum extent permitted by applicable laws, you agree
                to defend, indemnify and hold us, our affiliates and licensors
                and their respective officers, directors, employees,
                contractors, agents, licensors and suppliers harmless from and
                against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses or fees (including reasonable
                attorneys&apos; fees and other costs of defense) resulting from
                your violation of these Terms of Use, your violation of the
                rights of a third party (including any Publisher), or your use
                of the Developer Hub or the Developer Hub Content other than as
                permitted herein. We will be entitled, at our sole expense, to
                participate in the defense and settlement of the claim or action
                with counsel of our choosing. You may not settle any claims
                without our prior written consent (which will not be
                unreasonably withheld).
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>LIMITATION OF LIABILITY</u>
                </b>
              </em>
              <span>
                . TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                SHALL FIREEYE OR ITS SUPPLIERS/LICENSORS (INCLUDING PUBLISHERS)
                BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED
                TO, DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF
                PROFITS, GOODWILL OR BUSINESS, LOSS OR CORRUPTION OF DATA, OR
                COSTS TO PROCURE SUBTITUTE GOODS OR SERVICES, HOWEVER CAUSED,
                WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS
                ACTION RESULTING FROM USE OF, OR UNAVAILABILITY OF, THE
                DEVELOPER HUB OR THE DEVELOPER HUB CONTENT OR ARISING OUT OF THE
                USE OR PERFORMANCE OF THE DEVELOPER HUB OR DEVELOPER HUB
                CONTENT, REGARDLESS OF WHETHER FIREEYE, ANY PUBLISHER, OR AN
                AUTHORIZED FIREEYE REPRESENTATIVE HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS WILL APPLY EVEN
                IF THE REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>
                    <span>
                      Compliance with Laws; Reservation of Rights; Export
                    </span>
                  </u>
                </b>
              </em>
              <span>
                . You will use the Developer Hub and Developer Hub Content in
                compliance with all applicable laws, rules, regulations and
                guidelines. Except for the rights explicitly granted to you in
                these Terms of Use, all right, title and interest in the
                Developer Hub and the Developer Hub Content are reserved and
                retained by their respective providers, Publishers, licensors
                and rights holders. You do not acquire any ownership rights in
                the Developer Hub or the Developer Hub Content as a result of
                using the Developer Hub or downloading, accessing, or using the
                Developer Hub Content. You may not use or otherwise export or
                re-export the Developer Hub Content except as authorized by
                United States law and the laws of the jurisdiction in which the
                Developer Hub Content originated or were obtained. In
                particular, but without limitation, the Developer Hub Content
                may not be exported or re-exported (a) into any U.S. embargoed
                countries or (b) to anyone on the U.S Treasury Department’s list
                of Specially Designated Nationals or the U.S. Department of
                Commerce Denied Person’s List or Entity List. If you access the
                Developer Hub from outside the United States, you do so on your
                own initiative and are responsible for compliance with local
                laws. In addition, you are responsible for complying with any
                local laws which may impact your right to import, export,
                download or use the Developer Hub Content. Access to the
                Developer Hub from territories where the Developer Hub or any of
                its services or products are illegal is prohibited.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Termination</u>
                </b>
              </em>
              <span>
                . Your rights under these Terms of Use will automatically
                terminate without notice if you fail to comply with any of its
                terms. Further, FireEye may terminate these Terms of Use and the
                licenses granted herein at any time and for any or no reason by
                giving you written notice. In case of any such termination, you
                must cease all use of the Developer Hub and the Developer Hub
                Content, and FireEye may immediately revoke your access to the
                Developer Hub.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Governing Law; Jurisdiction; Jury Trial Waiver</u>
                </b>
              </em>
              <span>
                . These Terms of Use are governed by the laws of the State of
                California, without regard to its conflicts of laws rules, and
                each party irrevocably and unconditionally agrees to submit to
                the exclusive jurisdiction of the courts located in the Santa
                Clara County in the State of California. You agree to waive all
                defenses of lack of personal jurisdiction and Developer Hub
                non-conveniens, irrevocably waive your right to a jury trial and
                agree that process may be served in a manner authorized by
                applicable law or court rule. Except in circumstances where a
                party seeks urgent injunctive relief, before commencing any
                court proceedings, if any dispute arises under these Terms of
                Use, the parties will negotiate in good faith to resolve the
                dispute. The terms of the United Nations Convention on Contracts
                for the Sale of Goods do not apply to these Terms of Use.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>DMCA Process</u>
                </b>
              </em>
              <span>
                . If you believe that any Developer Hub Content violates your
                copyright, please notify FireEye’s copyright agent in writing.
                The contact information for FireEye’s copyright agent is at the
                bottom of this section. FireEye cannot take any action unless
                you give us all the information required by applicable law. In
                order for FireEye to take action, you must include the following
                in your notice:
              </span>
              <ul>
                <li>provide your physical or electronic signature;</li>
                <li>
                  <span>
                    identify the copyrighted work that you believe is being
                    infringed;
                  </span>
                </li>
                <li>
                  <span>
                    identify the item that you think is infringing and include
                    sufficient information about where the material is located
                    (including which page in the Developer Hub) so that FireEye
                    can find it;
                  </span>
                </li>
                <li>
                  <span>
                    provide FireEye with a way to contact you (such as address,
                    telephone number, or email);
                  </span>
                </li>
                <li>
                  <span>
                    provide a statement that you believe in good faith that the
                    item identified as infringing is not authorized by the
                    copyright owner, its agent, or the law to be used by the
                    Publisher or FireEye; and
                  </span>
                </li>
                <li>
                  <span>
                    provide a statement that the information you provide in the
                    notice is accurate, and that (under penalty of perjury), you
                    are authorized to act on behalf of the copyright owner whose
                    work is being infringed.
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  Here is the contact information for FireEye’s copyright agent:
                </span>
              </p>

              <p className="terms-of-usecontainer--address" align="center">
                <span>
                  Copyright Enforcement
                  <br />
                  Attention: Legal Department
                  <br />
                  FireEye, Inc.
                  <br />
                </span>
                <span>
                  <a href="mailto:copyrights@FireEye.com">
                    copyrights@FireEye.com
                  </a>
                </span>
              </p>
            </li>
            <li>
              <em>
                <b>
                  <u>Assignment</u>
                </b>
              </em>
              <span>
                . You may not assign or transfer the agreement formed by your
                agreeing to these Terms of Use. FireEye may freely assign,
                transfer, and delegate its rights and obligations under these
                Terms of Use. You acknowledge and agree that FireEye’s
                affiliates, contractors, Publishers, and service providers may
                exercise all rights of FireEye under these Terms of Use.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Weblinks</u>
                </b>
              </em>
              <span>
                . Certain links provided herein permit you to leave this site
                and enter non- FireEye sites. These linked sites are not under
                FireEye‘s control. FireEye is not responsible for the contents
                of any linked site or any changes or updates to such sites.
                FireEye is providing these links to you only as a convenience.
                The inclusion of any link does not imply endorsement by FireEye
                of any linked site. FIREEYE’S PUBLICATION OF INFORMATION
                REGARDING THIRD-PARTY PRODUCTS OR SERVICES DOES NOT CONSTITUTE
                AN ENDORSEMENT REGARDING THE SUITABILITY OF SUCH PRODUCTS OR
                SERVICES OR A WARRANTY, REPRESENTATION OR ENDORSEMENT OF SUCH
                PRODUCTS OR SERVICES, EITHER ALONE OR IN COMBINATION WITH ANY
                OTHER PRODUCT OR SERVICE. Linking to this site is subject to
                FireEye policies. If you would like to link to FireEye’s web
                site, please contact us at:&nbsp;
              </span>
              <span>
                <a href="mailto:webmaster@FireEye.com">webmaster@FireEye.com</a>
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Copyright to Developer Hub</u>
                </b>
              </em>
              <span>
                . All content included on the Developer Hub website, such as
                text, graphics, logos, button icons, images, audio clips,
                digital downloads, data compilations, and software, is the
                property of FireEye or its content suppliers and protected by
                United States and international copyright laws. The compilation
                of all content on the Developer Hub website is the exclusive
                property of FireEye and protected by U.S. and international
                copyright laws. The Developer Hub website or any portion of the
                Developer Hub website may not be reproduced, duplicated, copied,
                sold, resold, visited, or otherwise exploited for any commercial
                purpose without express written consent of FireEye. You may not
                frame or utilize framing techniques to enclose any trademark,
                logo, or other proprietary information (including images, text,
                page layout, or form) of the Developer Hub website without
                express written consent of FireEye. You may not use any meta
                tags or any other “hidden text” utilizing FireEye’s name or
                trademarks without the express written consent of FireEye. Any
                unauthorized use terminates the permissions and licenses granted
                under these Terms of Use by FireEye.
              </span>
            </li>
            <li>
              <em>
                <b>
                  <u>Miscellaneous</u>
                </b>
              </em>
              <span>
                . No waiver of these Terms of Use by us shall be deemed a
                further or continuing waiver of such term or condition or any
                other term or condition, and any failure of ours to assert a
                right or provision under these Terms of Use shall not constitute
                a waiver of such right or provision. If any provision of these
                Terms of Use is held by a court of competent jurisdiction to be
                invalid, illegal or unenforceable for any reason, such provision
                shall be eliminated or limited to the minimum extent such that
                the remaining provisions of the Terms of Use will continue in
                full force and effect. There are no third
              </span>
              ‐
              <span>
                party beneficiaries to these Terms of Use. These Terms of Use
                (including any applicable EULA, etc.) and our Privacy Policy
                constitute the sole and entire agreement between you and us with
                respect to the Developer Hub and Developer Hub Content, except
                as expressly set forth herein, and supersede all prior and
                contemporaneous understandings, agreements, representations and
                warranties, both written and oral, with respect to the Developer
                Hub Website. For the avoidance of doubt, these Terms of Use do
                not supersede any written agreement between you and FireEye for
                the use of, or the right to which you have been granted the
                right to use or access, a FireEye product, service, or
                subscription.
              </span>
            </li>
          </ol>
        </li>
      </ol>

      <p>
        <em>
          <b>
            <u>Last Updated</u>
          </b>
        </em>
        : September 19, 2019
      </p>
    </article>
  </Layout>
)

export default TermsOfUsePage
